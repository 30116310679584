'use client'

import { Suspense } from 'react'

import { MAIN_PAGE } from '@/constants/navigation'
import { ArrowLeftIcon } from '@/media'
import { CustomLink } from '@/components/CustomLink'
import { Spinner } from '@/components/Spinner'

export default function Custom404() {
  return (
    <Suspense fallback={<Spinner />}>
      <div className="flex grow bg-gray-100 items-center justify-center">
        <div className="flex flex-col items-center">
          <span className="text-base font-medium leading-5">Ошибка 404 :(</span>
          <span className="text-sm font-medium leading-4 mt-2 mb-4 text-gray-500">
            Упс! Такой страницы не существует
          </span>
          <CustomLink
            className={`flex h-10 px-4 items-center border hover:border-gray-300
            border-gray-200 rounded-lg hover:duration-500 bg-white`}
            href={MAIN_PAGE}
          >
            <span className="block mr-2 text-sm leading-4 font-medium text-gray-900">
              На главную
            </span>
            <div className="relative top-px scale-x-[-1]">
              <ArrowLeftIcon />
            </div>
          </CustomLink>
        </div>
      </div>
    </Suspense>
  )
}
