const LeafLeftIcon = () => {
    return (
        <svg width="12" height="18" viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Group">
                <path id="Vector" d="M10.8516 17.8375C10.8516 17.8375 13.0378 15.3047 10.2384 13.3984C10.2384 13.3984 8.05216 15.9446 10.8516 17.8375Z" fill="#C19552"/>
                <path id="Vector_2" d="M8.02438 15.6357C8.02438 15.6357 10.8505 13.1962 8.0777 10.6367C8.06437 10.6367 5.23828 13.0896 8.02438 15.6357Z" fill="#C19552"/>
                <path id="Vector_3" d="M9.70578 17.1171C9.70578 17.1171 7.70618 13.9577 4.77344 16.3305C4.77344 16.3305 6.77304 19.4899 9.70578 17.1171Z" fill="#C19552"/>
                <path id="Vector_4" d="M6.39968 13.5979C6.39968 13.5979 5.4532 10.5185 2.48047 11.8249C2.48047 11.8249 3.42694 14.9043 6.39968 13.5979Z" fill="#C19552"/>
                <path id="Vector_5" d="M4.71933 10.0383C4.71933 10.0383 3.97282 6.90558 0.920096 8.01203C0.906766 8.01203 1.65328 11.1447 4.71933 10.0383Z" fill="#C19552"/>
                <path id="Vector_6" d="M3.75979 6.79915C3.75979 6.79915 3.8931 2.93326 0.000547778 3.25319C0.000547778 3.25319 -0.132759 7.10575 3.75979 6.79915Z" fill="#C19552"/>
                <path id="Vector_7" d="M5.50493 12.3318C5.50493 12.3318 8.54432 10.5989 6.45141 7.74609C6.45141 7.74609 3.41202 9.47908 5.50493 12.3318Z" fill="#C19552"/>
                <path id="Vector_8" d="M4.14604 8.35779C4.14604 8.35779 7.35872 7.37133 5.999 4.25195C5.999 4.26528 2.78631 5.25175 4.14604 8.35779Z" fill="#C19552"/>
                <path id="Vector_9" d="M3.4808 5.03899C3.4808 5.03899 7.26671 3.63927 5.42708 0C5.42708 0 1.64117 1.39972 3.4808 5.03899Z" fill="#C19552"/>
            </g>
        </svg>
    )
}

export { LeafLeftIcon };