'use client'

import Input from '../Input'

interface Props {
  value: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  isError?: boolean
  onSubmit: () => void
}

export function EmailCheckForm({ value, onChange, isError, onSubmit }: Props) {
  function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter') {
      onSubmit?.()
    }
  }

  return (
    <Input
      value={value}
      onChange={onChange}
      isErrorAuth={isError}
      inputId="emailInput"
      autoComplete="off"
      label="Ваш адрес электронной почты"
      placeholder="Введите email"
      type="string"
      inputMode="text"
      className="w-full"
      onKeyDown={handleKeyDown}
    />
  )
}
