'use client'

import { useState } from 'react'

import { useAuthSteps } from '@/contexts/auth.steps.context'
import { useUser } from '@/contexts/user.context'
import { checkEmailCode, registration } from '@/services/apiService'
import { correctCodeApi, correctCodeOutput, correctPhoneApi } from '@/utils/auth'

import { Body } from '../Body'
import { Footer } from '../Footer'
import { Header } from '../Header'
import { RetryEmailCode } from '../RetryEmailCode'
import { CodeCheckForm } from '../forms/CodeCheckForm'
import AuthStepLayout from './AuthStepLayout'

const VALID_CODE_LENGTH = 5

export function EmailConfirmStep() {
  const authStepsContext = useAuthSteps()
  const userContext = useUser()
  const [code, setCode] = useState('')
  const phone = authStepsContext?.phone || ''
  const email = authStepsContext?.email || ''
  const name = authStepsContext?.name || ''

  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)

  function handleCodeChange(e: React.ChangeEvent<HTMLInputElement>) {
    const correctValue = correctCodeOutput(e.target.value)
    setCode?.(correctValue)

    if (correctValue.length === VALID_CODE_LENGTH) {
      handleNext(correctValue)
    }
  }

  async function handleRegister() {
    setIsLoading(true)

    const response = await registration({
      email,
      name,
      phone: correctPhoneApi(phone)
    })

    const token = response.data?.token

    if (token) {
      userContext?.onUserLogin(token)
    } else {
      setIsError(true)
      setIsLoading(false)
    }
  }

  async function handleCheckEmailCode(code: string) {
    if (code.length === VALID_CODE_LENGTH) {
      setIsLoading(true)

      const response = await checkEmailCode(email, correctCodeApi(code))
      if (response.data?.status === 200) {
        handleRegister()
      } else {
        setIsError(true)
        setIsLoading(false)
      }
    } else {
      handleRegister()
    }
  }

  async function handleNext(localCode = code) {
    handleCheckEmailCode(localCode)
  }

  return (
    <AuthStepLayout>
      <Header title="Подтвердите почту" />
      <Body>
        <CodeCheckForm
          value={code}
          onChange={handleCodeChange}
          isErrorAuth={isError}
          label={`На адрес ${email} поступит письмо.<br/>Введите код из письма`}
        />
        <RetryEmailCode onRetry={handleNext} />
      </Body>
      <Footer
        onNextClick={handleNext}
        onPrevClick={authStepsContext?.setPrevStep}
        isLoading={isLoading}
      />
    </AuthStepLayout>
  )
}

export default EmailConfirmStep
