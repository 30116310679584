'use client'

import { DocumentIcon } from '@/media'

export function PublicOffer() {
  return (
    <div className="mt-5 bg-gray-100 rounded-[12px] py-4 px-4 lg:px-5 flex items-center">
      <DocumentIcon />
      <span className="block ml-3 font-medium text-gray-500 text-xs leading-[17px]">
        Нажимая “Продолжить” вы принимаете <br className="hidden lg:block" />
        условия
        <a
          href="https://priceninja.ru/oferta"
          target="_blank"
          rel="noreferrer"
          className="inline-block ml-1 underline cursor-pointer"
        >
          публичной оферты
        </a>
      </span>
    </div>
  )
}
