import { useEffect, useState } from 'react'

import { usePathname } from 'next/navigation'

const LOGIN = 'Ninja'
const PASSWORD = 'priceninja'
const LOCAL_STORAGE_KEY = 'isTestAuthPast'

export const useTestServerAuth = () => {
  const pathname = usePathname()

  const needTestServerAuth = pathname.includes('test') && !localStorage.getItem(LOCAL_STORAGE_KEY)
  const [isAuthenticated, setIsAuthenticated] = useState(!needTestServerAuth)

  useEffect(() => {
    if (!needTestServerAuth) return

    const checkPassword = () => {
      const userLogin = prompt('Введите логин для доступа к сайту:')
      const userPassword = prompt('Введите пароль для доступа к сайту:')

      if (userPassword === PASSWORD && userLogin === LOGIN) {
        setIsAuthenticated(true)
        localStorage.setItem(LOCAL_STORAGE_KEY, 'true')
      } else {
        alert('Неправильные данные. Доступ запрещен.')
      }
    }

    checkPassword()
  }, [needTestServerAuth])

  return isAuthenticated
}
