'use client'

import React, { useEffect, useState, useRef } from 'react'

interface ProgressBarProps {
  duration?: number
}

function ProgressBar({ duration = 5000 }: ProgressBarProps) {
  const [progress, setProgress] = useState(0)
  const intervalRef = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setProgress(prevProgress => {
        if (prevProgress >= 100) {
          if (intervalRef.current) clearInterval(intervalRef.current)
          return 100
        }
        return prevProgress + 1
      })
    }, duration / 100)

    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current)
    }
  }, [duration])

  return (
    <div className="w-full overflow-hidden fixed top-0 left-0 w-full h-1 bg-gray-200">
      <div
        className="h-full bg-blue-600 transition-all duration-500 ease-out"
        style={{ width: `${progress}%` }}
      />
    </div>
  )
}

export default ProgressBar
