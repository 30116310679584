'use client'

export interface HeaderProps {
  title: string
}

export function Header({ title }: HeaderProps) {
  return (
    <h3
      className={`px-5 lg:px-10 py-4 lg:py-[30px] border-b border-gray-100
    text-black text-[18px] leading-[27px] lg:text-2xl lg:leading-[34px] font-semibold`}
    >
      {title}
    </h3>
  )
}
