'use client'

import React from 'react'

import { EmptyArchiveIcon } from '@/media'

interface Props {
  onRetry: () => void
}

export function RetryEmailCode({ onRetry }: Props) {
  return (
    <button
      className="mt-5 flex bg-none border-none items-center text-blue-600 "
      onClick={() => onRetry()}
    >
      <EmptyArchiveIcon />
      <span className="block text-base leading-[22px] font-medium ml-2 mt-[1px] cursor-pointer">
        Код не пришел
      </span>
    </button>
  )
}
