const LeafRightIcon = () => {
    return (
        <svg width="13" height="18" viewBox="0 0 13 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Group">
                <path id="Vector" d="M1.47653 17.8375C1.47653 17.8375 -0.709696 15.3047 2.08974 13.3984C2.08974 13.3984 4.27597 15.9446 1.47653 17.8375Z" fill="#C19552"/>
                <path id="Vector_2" d="M4.30374 15.6357C4.30374 15.6357 1.47765 13.1962 4.25042 10.6367C4.26375 10.6367 7.08985 13.0896 4.30374 15.6357Z" fill="#C19552"/>
                <path id="Vector_3" d="M2.62235 17.1171C2.62235 17.1171 4.62194 13.9577 7.55469 16.3305C7.55469 16.3305 5.55509 19.4899 2.62235 17.1171Z" fill="#C19552"/>
                <path id="Vector_4" d="M5.92845 13.5979C5.92845 13.5979 6.87492 10.5185 9.84766 11.8249C9.84766 11.8249 8.90118 14.9043 5.92845 13.5979Z" fill="#C19552"/>
                <path id="Vector_5" d="M7.60879 10.0383C7.60879 10.0383 8.35531 6.90558 11.408 8.01203C11.4214 8.01203 10.6748 11.1447 7.60879 10.0383Z" fill="#C19552"/>
                <path id="Vector_6" d="M8.56833 6.79915C8.56833 6.79915 8.43503 2.93326 12.3276 3.25319C12.3276 3.25319 12.4609 7.10575 8.56833 6.79915Z" fill="#C19552"/>
                <path id="Vector_7" d="M6.82319 12.3318C6.82319 12.3318 3.7838 10.5989 5.87671 7.74609C5.87671 7.74609 8.9161 9.47908 6.82319 12.3318Z" fill="#C19552"/>
                <path id="Vector_8" d="M8.18209 8.35779C8.18209 8.35779 4.9694 7.37133 6.32913 4.25195C6.32913 4.26528 9.54181 5.25175 8.18209 8.35779Z" fill="#C19552"/>
                <path id="Vector_9" d="M8.84732 5.03899C8.84732 5.03899 5.06142 3.63927 6.90105 0C6.90105 0 10.687 1.39972 8.84732 5.03899Z" fill="#C19552"/>
            </g>
        </svg>
    )
}

export { LeafRightIcon }