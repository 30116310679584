'use client'

import Input from '../Input'

interface Props {
  value: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  isErrorAuth?: boolean
  onSubmit: () => void
}

export function NameCheckForm({ value, onChange, isErrorAuth, onSubmit }: Props) {
  function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter') {
      onSubmit?.()
    }
  }

  return (
    <Input
      value={value}
      onChange={onChange}
      isErrorAuth={isErrorAuth}
      label="Как к вам обращаться?"
      placeholder="Введите имя"
      inputId="nameInput"
      type="string"
      className="w-full"
      inputMode="text"
      onKeyDown={handleKeyDown}
    />
  )
}
