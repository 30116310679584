'use client'

import React, { useEffect, useState } from 'react'

import { RefreshIcon } from '@/media/RefreshIcon'

interface Props {
  onRetry: () => void
  isSendSms?: boolean
  onRetrySecondsExpired?: () => void
}

const SECONDS_BEFORE_RETRY = 40

export function RetryCodeSecondsBlock({ onRetry, isSendSms, onRetrySecondsExpired }: Props) {
  const [retryPhoneSeconds, setRetryPhoneSeconds] = useState(SECONDS_BEFORE_RETRY)

  useEffect(() => {
    if (retryPhoneSeconds === 0) {
      onRetrySecondsExpired && onRetrySecondsExpired()
    }
    if (retryPhoneSeconds !== 0) {
      const interval = setInterval(() => {
        setRetryPhoneSeconds(prevCount => prevCount - 1)
      }, 1000)

      return () => clearInterval(interval)
    }
  }, [retryPhoneSeconds, onRetrySecondsExpired])

  function handleRetry() {
    setRetryPhoneSeconds(SECONDS_BEFORE_RETRY)
    onRetry()
  }

  if (retryPhoneSeconds === 0) {
    return (
      <button className="flex bg-none border-none items-center mt-5" onClick={handleRetry}>
        <RefreshIcon />
        <span className="block text-blue-600 text-base leading-[22px] font-medium ml-2 mt-[1px]">
          {isSendSms ? 'Отправить смс' : 'Повторите звонок'}
        </span>
      </button>
    )
  }

  return (
    <div className="mt-5">
      <div className="flex space-x-2">
        <RefreshIcon className="stroke-gray-500" />
        <span className="block text-base leading-[22px] font-medium ml-2 mt-[1px] text-gray-500">
          {isSendSms ? 'Отправить смс' : 'Повторить звонок'} можно через {retryPhoneSeconds} сек
        </span>
      </div>
    </div>
  )
}
