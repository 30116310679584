export const NAVIGATION = {
  home: '/',
  groups: '/groups',
  group: '/group',
  product: '/product',
  products: '/products',
  auth: '/auth',
  events: '/events',
  cabinet: '/cabinet',
  tariff: '/cabinet/tariff',
  profile: '/cabinet/profile',
  history: '/cabinet/history',
  apiKeys: '/cabinet/api-keys',
  notifications: '/notifications',
  profit: '/profit'
}

export const PAGES_WITH_FILTERS = [
  NAVIGATION.groups,
  NAVIGATION.group,
  NAVIGATION.product,
  NAVIGATION.products,
  NAVIGATION.events,
  NAVIGATION.profit
]

export const MAIN_PAGE = NAVIGATION.products
