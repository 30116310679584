'use client'

import { useState } from 'react'

import { useAuthSteps } from '@/contexts/auth.steps.context'
import { sendRegistrationEmail } from '@/services/apiService'
import { validateEmail } from '@/utils/auth'

import { EmailCheckForm } from '../forms/EmailCheckForm'
import { Body } from '../Body'
import { Header } from '../Header'
import { Footer } from '../Footer'
import AuthStepLayout from './AuthStepLayout'

export function EmailStep() {
  const authStepsContext = useAuthSteps()
  const email = authStepsContext?.email || ''
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  async function handleSendEmail() {
    setIsLoading(true)
    setIsError(false)

    const response = await sendRegistrationEmail(email)

    if (response.data?.status === 200) {
      authStepsContext?.setNextStep()
    } else {
      setIsError(true)
    }

    setIsLoading(false)
  }

  async function handleNext() {
    if (email.length && validateEmail(email)) {
      handleSendEmail()
    } else {
      setIsError(true)
    }
  }

  function handleChangeEmail(e: React.ChangeEvent<HTMLInputElement>) {
    authStepsContext?.setEmail(e.target.value)
    setIsError(false)
  }

  return (
    <AuthStepLayout>
      <Header title="Последний шаг" />
      <Body>
        <EmailCheckForm
          value={email}
          onChange={handleChangeEmail}
          isError={isError}
          onSubmit={handleNext}
        />
      </Body>
      <Footer
        onNextClick={handleNext}
        onPrevClick={authStepsContext?.setPrevStep}
        isLoading={isLoading}
        isDisabled={isLoading || isError}
      />
    </AuthStepLayout>
  )
}
