'use client'

import { ReactNode, createContext, useCallback, useContext, useEffect, useState } from 'react'

import { usePathname, useRouter } from 'next/navigation'

import { NAVIGATION, MAIN_PAGE } from '@/constants/navigation'
import { getUser } from '@/services/apiService'
import { User } from '@/types/auth'
import { removeSessionToken } from '@/utils/authSessionCookies'

interface UserContextType {
  user: User | null
  onUserLogin: (token: string) => void
  onUserLogout: () => void
  updateUser: () => void
  updateMainPage: (mainPagePathName: string) => void
  isLoading: boolean
  productsLeft: number | undefined
  productsUsed: number | undefined
  mainPagePathName: string | null
}

const LOCAL_STORAGE_MAIN_PAGE_PATHNAME_KEY = 'mainPagePathName'

const UserContext = createContext<UserContextType | null>(null)
interface UserProviderProps {
  children: ReactNode
  initialUser?: User | null
}

const UserProvider = ({ children, initialUser }: UserProviderProps) => {
  const [user, setUser] = useState<User | null>(initialUser || null)
  const [isLoading, setIsLoading] = useState(false)
  const [mainPagePathName, setMainPagePathName] = useState(NAVIGATION.products)

  useEffect(() => {
    setMainPagePathName(
      localStorage.getItem(LOCAL_STORAGE_MAIN_PAGE_PATHNAME_KEY) || NAVIGATION.products
    )
  }, [])
  const pathname = usePathname()

  const router = useRouter()

  const updateUser = useCallback(async () => {
    try {
      const response = await getUser()
      const newUser = response.data?.data || null

      setUser(newUser)
    } catch (error) {
      console.error('error on update user', error)
    }
  }, [])

  const updateMainPage = useCallback((mainPagePathName: string) => {
    setMainPagePathName(mainPagePathName)
    localStorage.setItem(LOCAL_STORAGE_MAIN_PAGE_PATHNAME_KEY, mainPagePathName)
  }, [])

  const onUserLogin = async (token: string) => {
    setIsLoading(true)

    const url = `${MAIN_PAGE}?token=${encodeURIComponent(token)}`

    router.push(url)

    await updateUser()

    setIsLoading(false)
  }

  const onUserLogout = async () => {
    await removeSessionToken()

    setUser(null)

    router.push(NAVIGATION.auth)
  }

  // updateUser on each route change to update user pending_products data
  useEffect(() => {
    updateUser()
  }, [pathname, updateUser])

  return (
    <UserContext.Provider
      value={{
        user,
        productsLeft: user?.products_in_tariff_left,
        productsUsed: user?.products_used,
        onUserLogin,
        onUserLogout,
        isLoading,
        updateUser,
        updateMainPage,
        mainPagePathName
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

const useUser = () => {
  const context = useContext(UserContext)

  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider')
  }
  return context
}

export { UserProvider, useUser }
