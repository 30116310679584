const LogoutIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M10 18L10 18.2C10 19.1941 10.8059 20 11.8 20L19 21C20.1046 21 21 20.1046 21 19L21 5C21 3.89543 20.1046 3 19 3L11.8 4C10.8059 4 10 4.80589 10 5.8L10 6M6 12L16.5 12M16.5 12L13.5 15M16.5 12L13.5 9"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export { LogoutIcon }
