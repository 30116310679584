export const correctPhoneOutput = (value: string): string => {
  let valuesArray: string[] = Array.from(value).filter(
    ltr => ltr.charCodeAt(0) > 47 && ltr.charCodeAt(0) < 58
  )

  if (valuesArray.length > 0 && (valuesArray[0] === '8' || valuesArray[0] === '7')) {
    valuesArray = valuesArray.slice(1);
  }

  const [operatorCode, number3, number21, number22] = [
    valuesArray.slice(0, 3).join(''),
    valuesArray.slice(3, 6).join(''),
    valuesArray.slice(6, 8).join(''),
    valuesArray.slice(8, 10).join('')
  ]

  let result = ''

  if (operatorCode.length) result += `${operatorCode}`
  if (number3.length) result += ` ${number3}`
  if (number21.length) result += ` ${number21}`
  if (number22.length) result += ` ${number22}`
  return result
}

export const correctPhoneApi = (value: string): string => {
  return `+7${value.split(' ').join('')}`
}

export const correctCodeOutput = (value: string): string => {
  const valuesArray: string[] = Array.from(value).filter(
    ltr => ltr.charCodeAt(0) > 47 && ltr.charCodeAt(0) < 58
  )

  const [first, second] = [valuesArray.slice(0, 2).join(''), valuesArray.slice(2, 4).join('')]

  let result = ''

  if (first.length) result += `${first}`
  if (second.length) result += ` ${second}`
  return result
}

export const correctCodeApi = (value: string): string => {
  return value.split(' ').join('')
}

export const validateEmail = (email: string) => {
  const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
  return re.test(String(email).toLowerCase())
}
