const PriceTableTimeIcon = ({color}) => {
    return (
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.80017 5.30005V8.90005L11.6002 10.1" stroke={color} stroke-width="1.2" stroke-linecap="round"/>
            <path
                d="M0.202614 7.65215L2.60342 4.60628C2.64612 4.5521 2.72825 4.5521 2.77096 4.60628L5.17176 7.65215C5.23896 7.73741 5.19137 7.88317 5.09634 7.88317H2.68719H0.278039C0.183009 7.88317 0.135418 7.73741 0.202614 7.65215Z"
                fill={color}/>
            <path
                d="M0.202598 10.2039L2.61175 13.2604C2.6534 13.3132 2.72094 13.3132 2.7626 13.2604L5.17175 10.2039C5.23895 10.1186 5.19135 9.97288 5.09632 9.97288H0.278023C0.182993 9.97288 0.135402 10.1186 0.202598 10.2039Z"
                fill={color}/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M6.00012 14.9175L6.03625 14.9331C6.88491 15.2981 7.8197 15.5 8.80009 15.5C12.6661 15.5 15.8001 12.366 15.8001 8.5C15.8001 4.63401 12.6661 1.5 8.80009 1.5C7.8197 1.5 6.88491 1.7019 6.03625 2.06695L6.00012 2.08249V3.3888L6.51043 3.16929C7.21208 2.86747 7.98574 2.7 8.80009 2.7C12.0033 2.7 14.6001 5.29675 14.6001 8.5C14.6001 11.7033 12.0033 14.3 8.80009 14.3C7.98574 14.3 7.21208 14.1325 6.51043 13.8307L6.00012 13.6112V14.9175Z"
                  fill={color}/>
        </svg>

    )
}

export {PriceTableTimeIcon}
