'use client'

import { createContext, useContext, useState, useCallback, Suspense, useEffect } from 'react'

import { usePathname, useSearchParams } from 'next/navigation'

type RouteChangeContextProps = {
  onRouteChangeStart: () => void
  onRouteChangeComplete: () => void
  isRouteChanging?: boolean
}

type RouteChangeProviderProps = {
  children: React.ReactNode
}

const RouteChangeContext = createContext<RouteChangeContextProps>({} as RouteChangeContextProps)

function RouteChangeComplete(): null {
  const { onRouteChangeComplete } = useRouteChangeContext()

  const pathname = usePathname()
  const searchParams = useSearchParams()
  useEffect(() => onRouteChangeComplete(), [pathname, searchParams, onRouteChangeComplete])

  return null
}

function RouteChangeProvider({ children }: RouteChangeProviderProps): JSX.Element {
  const [isRouteChanging, setIsRouteChanging] = useState(true)

  const onRouteChangeStart = useCallback(() => {
    setIsRouteChanging(true)
  }, [])

  const onRouteChangeComplete = useCallback(() => {
    setIsRouteChanging(false)
  }, [])

  return (
    <RouteChangeContext.Provider
      value={{
        onRouteChangeStart,
        onRouteChangeComplete,
        isRouteChanging
      }}
    >
      {children}
      <Suspense>
        <RouteChangeComplete />
      </Suspense>
    </RouteChangeContext.Provider>
  )
}

const useRouteChangeContext = () => {
  const context = useContext<RouteChangeContextProps>(RouteChangeContext)

  if (context === undefined) {
    throw new Error('useRouteChangeContext must be used within the RouteChangeProvider')
  }

  return context
}

export { RouteChangeProvider, useRouteChangeContext }
