'use client'

import { useState } from 'react'

import { Strategy, useAuthSteps } from '@/contexts/auth.steps.context'
import { useUser } from '@/contexts/user.context'
import { checkPhoneNumber, checkPhoneNumberCode, login } from '@/services/apiService'
import { correctCodeApi, correctCodeOutput, correctPhoneApi } from '@/utils/auth'

import { Body } from '../Body'
import { Footer } from '../Footer'
import { Header } from '../Header'
import { RetryCodeSecondsBlock } from '../RetryCodeSecondsBlock'
import { CodeCheckForm } from '../forms/CodeCheckForm'
import AuthStepLayout from './AuthStepLayout'

const VALID_CODE_LENGTH = 5

const isCodeLengthValid = (phone: string) => phone.length === VALID_CODE_LENGTH

export function PhoneCodeCheckStep() {
  const authStepsContext = useAuthSteps()
  const userContext = useUser()
  const code = authStepsContext?.code || ''
  const phone = authStepsContext?.phone || ''
  const checkedPhone = authStepsContext?.checkedPhone || undefined
  const setCheckedPhone = authStepsContext?.setCheckedPhone
  const setCode = authStepsContext?.setCode
  const strategy = authStepsContext?.strategy
  const isSendSms = authStepsContext?.isSendSms
  const setIsSendSms = authStepsContext?.setIsSendSms

  const [isError, setIsError] = useState(false)
  const [succeeded, setSucceeded] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  function handleCodeChange(e: React.ChangeEvent<HTMLInputElement>) {
    const correctValue = correctCodeOutput(e.target.value)
    setCode?.(correctValue)
    setIsError(false)

    if (isCodeLengthValid(correctValue)) {
      handleNext(correctValue)
    }
  }

  async function handleLogin(code: string) {
    setIsLoading(true)

    const response = await login(correctPhoneApi(phone), correctCodeApi(code))

    if (response.error || response.message) {
      setIsError(true)

      setIsLoading(false)
    } else {
      setSucceeded(true)
      const token = response.data?.token

      if (token) {
        await userContext?.onUserLogin(token)
      } else {
        setIsError(true)
        setIsLoading(false)
        setSucceeded(true)
      }
    }
  }

  async function handleCheckCode(code: string) {
    setIsLoading(true)

    // если мы проверяли этот телефон, пускаем без кода
    if (phone === checkedPhone) {
      authStepsContext?.setNextStep()
      return
    }

    const response = await checkPhoneNumberCode(correctPhoneApi(phone), correctCodeApi(code))
    const isSuccess = response.data?.is_success

    if (isSuccess) {
      setCheckedPhone?.(phone)
      authStepsContext?.setNextStep()
    } else {
      setIsError(true)
    }

    setIsLoading(false)
  }

  async function handleNext(localCode = code) {
    if (isCodeLengthValid(localCode)) {
      if (strategy === Strategy.Auth) handleLogin(localCode)
      if (strategy === Strategy.Registration) handleCheckCode(localCode)
    } else {
      setIsError(true)
    }
  }

  async function handleRetry() {
    await checkPhoneNumber(correctPhoneApi(phone), 'sms')
  }

  function handleRetrySecondsExpired() {
    setIsSendSms?.(true)
  }

  return (
    <AuthStepLayout>
      <Header title="Подтвердите вход" />
      <Body>
        <CodeCheckForm
          value={code}
          onChange={handleCodeChange}
          isErrorAuth={isError}
          label={`На номер +7 ${phone} поступит <br/> ${isSendSms ? 'смс. Введите код из сообщения' : 'звонок. Введите последние 4 цифры'}`}
        />
        <RetryCodeSecondsBlock
          onRetry={handleRetry}
          onRetrySecondsExpired={handleRetrySecondsExpired}
          isSendSms
        />
      </Body>
      <Footer
        onNextClick={handleNext}
        onPrevClick={authStepsContext?.setPrevStep}
        isLoading={isLoading}
        isDisabled={!isCodeLengthValid(code) || isLoading || isError}
        nextStepText={succeeded ? 'Успешно...' : 'Продолжить'}
      />
    </AuthStepLayout>
  )
}
