'use client'

import { useState } from 'react'

import { useAuthSteps } from '@/contexts/auth.steps.context'
import { correctPhoneOutput } from '@/utils/auth'

import { Body } from '../Body'
import { Footer } from '../Footer'
import { Header } from '../Header'
import { PhoneCheckForm } from '../forms/PhoneCheckForm'
import AuthStepLayout from './AuthStepLayout'

const VALID_PHONE_LENGTH = 13

const isPhoneLengthValid = (phone: string) => phone.length === VALID_PHONE_LENGTH

export function PhoneCheckStep() {
  const authStepsContext = useAuthSteps()
  const [isLoading, setIsLoading] = useState(false)
  const phone = authStepsContext?.phone || ''
  const phoneError = authStepsContext?.phoneError || false
  const checkedPhone = authStepsContext?.checkedPhone || undefined
  const setPhoneError = authStepsContext?.setPhoneError
  const onCheckPhoneNumber = authStepsContext?.onCheckPhoneNumber

  function handlePhoneChange(e: React.ChangeEvent<HTMLInputElement>) {
    const correctValue = correctPhoneOutput(e.target.value)

    setPhoneError?.(false)

    if (authStepsContext?.setPhone) {
      authStepsContext.setPhone(correctValue)
    }

    handleNext(correctValue)
  }

  async function handleNext(localPhone = phone) {
    if (isPhoneLengthValid(localPhone)) {
      setPhoneError?.(false)

      // проверяем совпадает ли этот телефон с уже проверенным
      if (checkedPhone !== localPhone) {
        setIsLoading(true);
        onCheckPhoneNumber?.(localPhone)
      }

      authStepsContext?.setNextStep()
    }
  }

  return (
    <AuthStepLayout>
      <Header title="Войти или зарегистрироваться" />
      <Body>
        <PhoneCheckForm value={phone} onChange={handlePhoneChange} isError={phoneError} />
      </Body>
      <Footer onNextClick={handleNext} isDisabled={!isPhoneLengthValid(phone)} isLoading={isLoading} />
    </AuthStepLayout>
  )
}
