'use client'

import Script from 'next/script'
import Image from 'next/image'

import { inter, montserrat } from '@/fonts'
import '@/styles/index.css'
import ProgressBar from '@/components/ProgressBar'
import { useRouteChangeContext } from '@/contexts/route.change.context'
import { startBugsnag } from '@/utils/bugsnag'

import { TestCheck } from './testCheck'

interface AppProps {
  children: React.ReactNode
}

startBugsnag()

export default function App({ children }: AppProps) {
  return (
    <HTMLLayout>
      <TestCheck>{children}</TestCheck>
    </HTMLLayout>
  )
}

export function AppInnerWrapper({ children }: AppProps) {
  const { isRouteChanging } = useRouteChangeContext()

  return (
    <>
      {/* пока нет котента показывать ProgressBar */}
      <ProgressBar />
      <div className="bg-white relative t-0 min-h-screen flex flex-col grow">
        {/* при смене урла показывать ProgressBar */}
        {isRouteChanging && <ProgressBar />}
        {children}
      </div>
    </>
  )
}

function HTMLLayout({ children }: AppProps) {
  const isProduction = process.env.NODE_ENV === 'production'
  return (
    <html lang="ru" className={`${inter.variable} ${montserrat.variable} text-gray-900`}>
      <body>
        <div id="root" className="min-h-screen flex flex-col">
          {children}
        </div>
        {isProduction && (
          <>
            {/* Yandex.Metrika counter */}
            <Script strategy="afterInteractive" id="yandex-metrika">
              {`
                (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){
                  (m[i].a=m[i].a||[]).push(arguments)
                };
                m[i].l=1*new Date();
                for (var j = 0; j < document.scripts.length; j++) {
                  if (document.scripts[j].src === r) { return; }}
                    k=e.createElement(t),
                    a=e.getElementsByTagName(t)[0],
                    k.async=1,k.src=r,
                    a.parentNode.insertBefore(k,a)
                  }) (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
                ym(97255592, "init", { clickmap:true, trackLinks:true, accurateTrackBounce:true });
                `}
            </Script>
            <noscript>
              <div>
                <Image
                  src="https://mc.yandex.ru/watch/97255592"
                  style={{ position: 'absolute', left: '-9999px' }}
                  alt=""
                  fill={true}
                />
              </div>
            </noscript>

            {/* Google tag (gtag.js) */}
            <Script
              strategy="afterInteractive"
              src="https://www.googletagmanager.com/gtag/js?id=G-XG6WL2ELX8"
              id="gtm"
            />
            <Script strategy="afterInteractive" id="data-layer">
              {`
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', 'G-XG6WL2ELX8');
              `}
            </Script>

            <Script
              strategy="afterInteractive"
              src="https://widget.cloudpayments.ru/bundles/cloudpayments"
              id="cloud-payments"
            />
          </>
        )}
      </body>
    </html>
  )
}
