'use client'

import Input from '../Input'

export interface PhoneCheckFormProps {
  value: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  isError?: boolean
}

export function PhoneCheckForm({ value, onChange, isError }: PhoneCheckFormProps) {
  return (
    <Input
      value={value}
      onChange={onChange}
      isErrorAuth={isError}
      inputId="phoneInput"
      label={`Введите <br />
          номер телефона`}
      placeholder="000 000 00 00"
      type="tel"
      inputMode="numeric"
      showPhonePrefix={true}
      className="w-[220px] lg:w-[260px]"
    />
  )
}
