'use client'

import { ReactNode, createContext, useContext, useState } from 'react'

import { checkPhoneNumber } from '@/services/apiService'
import { correctPhoneApi } from '@/utils/auth'

enum AuthStep {
  PhoneCheck = 'phoneCheck',
  CodeCheck = 'codeCheck',
  Name = 'name',
  Email = 'email',
  EmailConfirm = 'emailConfirm'
}

export enum Strategy {
  Registration = 'registration',
  Auth = 'auth'
}

interface AuthStepsContextType {
  step: string
  setNextStep: () => void
  setPrevStep: () => void
  phone?: string
  setPhone?: (phone: string) => void
  setStrategy: (strategy: Strategy) => void
  strategy: Strategy
  code: string
  setCode: (code: string) => void
  name: string
  setName: (name: string) => void
  email: string
  setEmail: (email: string) => void
  phoneError: boolean
  setPhoneError: (error: boolean) => void
  onCheckPhoneNumber: (phone: string) => void
  setCheckedPhone: (phone: string) => void
  checkedPhone: string | undefined
  isSendSms: boolean
  setIsSendSms: (isSendSms: boolean) => void
}

const AuthStepsContext = createContext<AuthStepsContextType | null>(null)

interface AuthStepsProviderProps {
  children: ReactNode
}

const AuthStepsProvider = ({ children }: AuthStepsProviderProps) => {
  const [step, setStep] = useState<AuthStep>(AuthStep.PhoneCheck)
  const [strategy, setStrategy] = useState<Strategy>(Strategy.Auth)
  const [phone, setPhone] = useState('')
  const [phoneError, setPhoneError] = useState(false)
  const [code, setCode] = useState('')
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [isSendSms, setIsSendSms] = useState(false)
  // записываем последний телефон по которому проверили код, чтобы не просить код заново
  const [checkedPhone, setCheckedPhone] = useState<string | undefined>(undefined)

  const setNextStep = () => {
    if (strategy !== Strategy.Registration && step !== AuthStep.PhoneCheck) return

    switch (step) {
      case AuthStep.PhoneCheck:
        if (checkedPhone !== phone) {
          setStep(AuthStep.CodeCheck)
        } else {
          setStep(AuthStep.Name)
        }
        break
      case AuthStep.CodeCheck:
        setStep(AuthStep.Name)
        break
      case AuthStep.Name:
        setStep(AuthStep.Email)
        break
      case AuthStep.Email:
        setStep(AuthStep.EmailConfirm)
        break
    }
  }

  async function onCheckPhoneNumber(phone: string) {
    const response = await checkPhoneNumber(correctPhoneApi(phone), isSendSms ? 'sms' : 'phone')
    if (!response || response.error || response.message) {
      setPhoneError(true)
      setPrevStep()
    }

    const strategy = response.data?.strategy

    if (strategy) {
      setStrategy(strategy)
    }
  }

  const setPrevStep = () => {
    switch (step) {
      case AuthStep.PhoneCheck:
        setStep(AuthStep.PhoneCheck)
        break
      case AuthStep.CodeCheck:
        setStep(AuthStep.PhoneCheck)
        setIsSendSms(true)
        break
      case AuthStep.Name:
        setStep(AuthStep.CodeCheck)
        break
      case AuthStep.Email:
        setStep(AuthStep.Name)
        break
      case AuthStep.EmailConfirm:
        setStep(AuthStep.Email)
        break
    }
  }

  return (
    <AuthStepsContext.Provider
      value={{
        setCheckedPhone,
        checkedPhone,
        step,
        phone,
        strategy,
        code,
        name,
        email,
        setNextStep,
        setPrevStep,
        setPhone,
        setStrategy,
        setCode,
        setName,
        setEmail,
        phoneError,
        setPhoneError,
        onCheckPhoneNumber,
        isSendSms,
        setIsSendSms
      }}
    >
      {children}
    </AuthStepsContext.Provider>
  )
}

const useAuthSteps = () => {
  return useContext(AuthStepsContext)
}

export { AuthStepsProvider, useAuthSteps, AuthStep }
