import React from 'react'

import { HorizontalEnteringTransition } from '@/components/transitions/HorizontalEnteringTransition'

type AuthStepLayoutProps = {
  children: React.ReactNode
}

export default function AuthStepLayout({ children }: AuthStepLayoutProps) {
  return (
    <div className="bg-white rounded-xl">
      <HorizontalEnteringTransition>{children}</HorizontalEnteringTransition>
    </div>
  )
}
