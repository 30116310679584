import { MediaProps } from '@/types/media'

const ArrowRightIcon = ({
  width = '25',
  height = '24',
  className = 'stroke-gray-900'
}: MediaProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 25 24"
    fill="none"
    className={className}
  >
    <path d="M9.04688 5L16.0469 12L9.04688 19" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export { ArrowRightIcon }
