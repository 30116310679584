'use client'

import { AuthenticationLayout } from '@/components/LayoutSpecific/AuthenticationLayout'
import { AuthStepsProvider } from '@/contexts/auth.steps.context'

type AuthLayoutProps = {
  children: React.ReactNode
}

export default function AuthLayout({
  children // will be a page or nested layout
}: AuthLayoutProps) {
  return (
    <AuthenticationLayout>
      <AuthStepsProvider>{children}</AuthStepsProvider>
    </AuthenticationLayout>
  )
}
