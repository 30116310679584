'use client'

import { Suspense, useEffect } from 'react'

import * as Sentry from '@sentry/nextjs'
import Bugsnag from '@bugsnag/js'

import { ArrowLeftIcon } from '@/media'
import { MAIN_PAGE } from '@/constants/navigation'

import { CustomLink } from '../CustomLink'
import { Spinner } from '../Spinner'

export default function ErrorLayout({
  error,
  reset
}: {
  error: Error & { digest?: string }
  reset: () => void
}) {
  useEffect(() => {
    Sentry.captureException(error)
    Bugsnag.notify(error)
  }, [error])

  return (
    <Suspense fallback={<Spinner />}>
      <div className="flex grow  bg-gray-100 items-center justify-center">
        <div className="flex flex-col items-center">
          <span className="text-base font-medium leading-5">Упс! Что-то пошло не так :(</span>
          <span className="text-sm font-medium leading-5 mt-2 text-gray-500">
            Расскажите, как вы попали на эту страницу
          </span>
          <span className="text-sm font-medium leading-5 mb-4 text-gray-500">
            и мы быстро все поправим{' '}
            <a className="underline underline-offset-[3px]" href="mailto:hello@priceninja.ru">
              hello@priceninja.ru
            </a>
          </span>
          <CustomLink
            className={`flex h-10 px-4 items-center border hover:border-gray-300
            border-gray-200 rounded-lg hover:duration-500 bg-white`}
            href={MAIN_PAGE}
          >
            <span className="block mr-2 text-sm leading-4 font-medium text-gray-900">
              На главную
            </span>
            <div className="relative top-px scale-x-[-1]">
              <ArrowLeftIcon />
            </div>
          </CustomLink>

          <button
            className={`flex h-10 px-4 items-center border hover:border-gray-300 mt-4
            border-gray-200 rounded-lg hover:duration-500 bg-white`}
            onClick={() => reset()}
          >
            <span className="block mr-2 text-sm leading-4 font-medium text-gray-900">
              или перезагрузить страницу
            </span>
          </button>
        </div>
      </div>
    </Suspense>
  )
}
