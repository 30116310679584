'use client'
import { useRef, useEffect } from 'react'

import cn from 'classnames'

import { RightArrow } from '@/media/RightArrow'

import { Spinner } from '../Spinner'

export interface FooterProps {
  onNextClick: () => void
  onPrevClick?: () => void
  isLastStep?: boolean
  isLoading?: boolean
  isDisabled?: boolean
  nextStepText?: string
}

export function Footer({
  onPrevClick,
  onNextClick,
  isLastStep = false,
  nextStepText = 'Продолжить',
  isLoading = false,
  isDisabled = false
}: FooterProps) {
  /**
   * флаг что кликнули на кнопку
   * если приходит loading = true, то мог сработать автоматический клик на кнопку
   * если на кнопку кликнули вручную, то анимировать "нажатие" не надо
   */
  const buttonHasBeenClicked = useRef(false)

  const handleNextClick = () => {
    onNextClick()
    buttonHasBeenClicked.current = true
  }

  useEffect(() => {
    if (!isLoading) {
      buttonHasBeenClicked.current = false
    }
  }, [isLoading])

  return (
    <div className="flex px-5 lg:px-10 py-4 lg:py-[30px] items-center space-x-4">
      {onPrevClick && (
        <button
          className={`flex items-center justify-center w-[60px] h-[60px]
            bg-blue-100 hover:duration-500 hover:bg-blue-200
            focus:outline-none focus:duration-500 focus:bg-blue-200
            rounded-[100px] transition duration-75 active:scale-90`}
          onClick={() => onPrevClick?.()}
        >
          <RightArrow className="rotate-180 stroke-blue-600" />
        </button>
      )}
      <button
        onClick={handleNextClick}
        className={cn(
          `bg-blue-600 block text-white text-base font-semibold leading-[20px]
          space-x-2.5 focus:outline-none focus:duration-500 focus:bg-blue-500
          rounded-[100px] py-5 pl-8 pr-6 flex grow lg:grow-0 justify-center duration-75 active:scale-90`,
          {
            'hover:duration-500 hover:bg-blue-500': !isLoading && !isDisabled,
            'opacity-50 cursor-not-allowed': isDisabled,
            'scale-up-button': isLoading && !buttonHasBeenClicked.current
          }
        )}
        disabled={isDisabled !== undefined ? isDisabled : isLoading}
      >
        {isLoading && <Spinner className="!w-5 !h-5" />}
        <span>{nextStepText}</span>
        {!isLastStep && <RightArrow className="stroke-white" />}
      </button>
    </div>
  )
}
