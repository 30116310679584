'use client'

import { usePathname, useSearchParams } from 'next/navigation'

import { useRouteChangeContext } from '@/contexts/route.change.context'

export interface CustomLinkProps {
  href: string
  className?: string
  children?: React.ReactNode
}

export function CustomLink({ href, children, className }: CustomLinkProps) {
  const pathname = usePathname()
  const searchParams = useSearchParams()

  const { onRouteChangeStart } = useRouteChangeContext()

  function handleRouteChange() {
    const currentSearchParams = searchParams.toString()
    const hrefCurrent = `${pathname}${currentSearchParams}`

    if (href !== hrefCurrent && onRouteChangeStart) {
      onRouteChangeStart()
    }
  }

  return (
    <a href={href} onClick={handleRouteChange} className={className}>
      {children}
    </a>
  )
}
