'use client'
import {
  PhoneCodeCheckStep,
  PhoneCheckStep,
  EmailStep,
  NameStep,
  EmailConfirmStep
} from '@/components/Authentication'
import { useAuthSteps, AuthStep } from '@/contexts/auth.steps.context'

export default function AuthPage() {
  const useAuthStepsContext = useAuthSteps()

  if (!useAuthStepsContext) {
    return null
  }

  const { step } = useAuthStepsContext

  if (step === AuthStep.PhoneCheck) return <PhoneCheckStep />

  if (step === AuthStep.CodeCheck) return <PhoneCodeCheckStep />

  if (step === AuthStep.Name) return <NameStep />

  if (step === AuthStep.Email) return <EmailStep />

  if (step === AuthStep.EmailConfirm) return <EmailConfirmStep />

  return null
}
