const NinjaIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="24" viewBox="0 0 23 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.1851 0.570784V14.9625L22.8599 17.7537H8.4665L5.6753 23.4286L5.6753 9.03745L0 6.24605V18.6561C0 21.2936 2.13808 23.4316 4.77554 23.4316H5.6753V23.429H18.0851C20.7226 23.429 22.8606 21.2909 22.8606 18.6535V17.7537H22.8604V5.3439C22.8604 2.70644 20.7223 0.568359 18.0848 0.568359H17.1851V0.568818L4.77604 0.568819C2.13858 0.568819 0.0004982 2.7069 0.000498315 5.34436L0.000498354 6.24414L14.3946 6.24414L17.1851 0.570784Z"
        fill="url(#paint0_linear_1737_3204)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1737_3204"
          x1="0"
          y1="0.568359"
          x2="26.0274"
          y2="27.8567"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00E691" />
          <stop offset="1" stopColor="#0145FF" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export { NinjaIcon }
