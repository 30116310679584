'use client'

import { HTMLInputAutoCompleteAttribute } from 'react'

import cn from 'classnames'

type InputMode = 'none' | 'text' | 'decimal' | 'numeric' | 'tel' | 'search' | 'email' | 'url'

export interface InputProps {
  value: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  isErrorAuth?: boolean
  inputId: string
  label: string
  placeholder: string
  type: string
  inputMode: InputMode
  showPhonePrefix?: boolean
  className?: string
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
  autoComplete?: HTMLInputAutoCompleteAttribute
}

function Input({
  value,
  onChange,
  isErrorAuth,
  placeholder,
  label,
  inputId,
  type,
  inputMode,
  showPhonePrefix = false,
  className = '',
  onKeyDown,
  autoComplete
}: InputProps) {
  return (
    <div>
      <label
        htmlFor={inputId}
        className="block text-black font-medium text-base leading-[22px] mb-4"
        dangerouslySetInnerHTML={{ __html: label }}
      />
      <div className="relative">
        <input
          onKeyDown={onKeyDown}
          type={type}
          id={inputId}
          value={value}
          onChange={onChange}
          autoComplete={autoComplete}
          autoFocus={true}
          inputMode={inputMode}
          className={cn(
            'border border-gray-200 hover:border-gray-300 rounded-xl',
            'hover:duration-500 focus:border-blue-600 cursor-pointer',
            'focus:outline-none',
            'p-4 lg:p-5 block text-xl leading-6 lg:text-2xl lg:leading-7',
            'text-black font-normal placeholder:text-gray-200',
            {
              'border-red-600 focus:border-red-600': isErrorAuth,
              'pl-11 lg:pl-14': showPhonePrefix,
              [className]: className
            }
          )}
          placeholder={placeholder}
        />
        {showPhonePrefix && (
          <span
            className={`block absolute text-xl leading-6 lg:text-2xl lg:leading-7
          text-black left-4 top-[17px] lg:left-5 lg:top-[21px]`}
          >
            +7
          </span>
        )}
      </div>
    </div>
  )
}

export default Input
