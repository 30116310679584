import qs from 'qs'
import * as Sentry from '@sentry/nextjs'
import Bugsnag from '@bugsnag/js'

import {
  AddApiKeyResponse,
  ApiKeyBody,
  ApiKeysData,
  ApiResponseData,
  BodyWithFilters,
  ChangeEmailCheckEmailCode,
  ChangeEmailSendEmailCode,
  ChangeEmailSentNewEmailCode,
  ChangeNameResponse,
  ChangePhoneCallNewPhone,
  ChangePhoneCallOldPhone,
  CheckEmailCodeBody,
  CheckEmailCodeResponse,
  CheckPhoneNumberBody,
  CheckPhoneNumberCodeBody,
  CheckPhoneNumberCodeResponse,
  CheckPhoneNumberResponse,
  CheckPromo,
  DeleteApiKeyResponse,
  FilterStatuses,
  FilterStatusResponse,
  LoginBody,
  LoginResponse,
  MessageResponse,
  PanelChartBody,
  PanelChartsData,
  PaymentHistory,
  ProductsFiltersData,
  ProfitBody,
  RegistrationBody,
  RegistrationResponse,
  SendInvoiceRequest,
  SendMassUploadResponse,
  SendRegistrationEmailBody,
  SendRegistrationEmailResponse,
  Tariffs,
  UserAuth
} from '@/types/api'
import { getSessionToken, removeSessionToken } from '@/utils/authSessionCookies'
import { startBugsnag } from '@/utils/bugsnag'

import { ProfitData } from './profitService'

startBugsnag()

const getEnvironmentHeader = () => {
  return process.env.NODE_ENV === 'development' ? 'localhost' : 'production'
}

class ApiService {
  private defaultHeaders: HeadersInit = {
    'Content-Type': 'application/json',
    Accept: 'application/json, text/plain, */*'
  }
  private baseUrl: string

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl
  }

  private buildHeaders(token?: string | null, defaultHeaders?: HeadersInit): HeadersInit {
    const headers: HeadersInit = {
      ...(defaultHeaders ?? this.defaultHeaders),
      Authorization: token ? `Bearer ${token}` : '',
      'X-Environment': getEnvironmentHeader()
    }

    return headers
  }

  async get<T extends MessageResponse & { errors?: unknown }, B = undefined>(
    endpoint: string,
    body?: B,
    token?: string | null
  ): Promise<ApiResponseData<T | null>> {
    try {
      const headers = this.buildHeaders(token)

      // Convert body to query string if method is GET
      if (body && Object.keys(body).length > 0) {
        const queryString = qs.stringify(body, { encode: false })
        endpoint += `?${queryString}`
      }

      const response = await fetch(`${this.baseUrl}${endpoint}`, {
        headers,
        method: 'GET'
        // ban server redirects
        // redirect: 'manual'
      })

      const status = response.status
      const data: T = await response.json()

      if (status !== 200 && status !== 201) {
        const error = data?.errors || data?.message || 'Unknown error'

        Sentry.captureException(new Error(error as string))
        Bugsnag.notify(error as Error)
      }

      // 401 unauthorised
      if (status === 401) {
        removeSessionToken()

        return { data: null, message: data?.message, queryString: endpoint }
      } else if (status !== 200) {
        return { data: null, message: data?.message, queryString: endpoint }
      } else {
        return { data, queryString: endpoint }
      }
    } catch (error) {
      Sentry.captureException(error)
      Bugsnag.notify(error as Error)

      return { data: null, error: String(error) }
    }
  }

  async getWithToken<T extends MessageResponse, B = undefined>(
    endpoint: string,
    body?: B
  ): Promise<ApiResponseData<T | null>> {
    const token = await getSessionToken()
    if (!token) return Promise.resolve({ data: null })

    return this.get(endpoint, body, token)
  }

  getBody<B>(body: B | undefined) {
    if (!body) return undefined
    if (body instanceof FormData) return body
    return JSON.stringify(body)
  }

  async post<T extends MessageResponse & { errors?: unknown }, B>(
    endpoint: string,
    body?: B,
    token?: string | null
  ): Promise<ApiResponseData<T | null>> {
    try {
      const headers = this.buildHeaders(
        token,
        body instanceof FormData ? { Accept: 'application/json, text/plain, */*' } : undefined
      )

      const response = await fetch(`${this.baseUrl}${endpoint}`, {
        method: 'POST',
        headers,
        body: this.getBody<B>(body)
        // ban server redirects
        // redirect: 'manual'
        // cache: 'force-cache'
      })

      const status = response.status
      const data: T = await response.json()

      if (status !== 200 && status !== 201) {
        const error = data?.errors || data?.message || 'Unknown error'
        Sentry.captureException(new Error(error as string))
        Bugsnag.notify(error as Error)
      }

      if (status === 401) {
        removeSessionToken()

        return { data: null, message: data?.message, status }
      } else if (status !== 200) {
        return { data: null, message: data?.message, status }
      } else {
        return { data, message: data?.message, status }
      }
    } catch (error) {
      Sentry.captureException(error)
      Bugsnag.notify(error as Error)

      return { data: null, error: String(error) }
    }
  }

  async postWithToken<T extends MessageResponse, B = undefined>(
    endpoint: string,
    body?: B
  ): Promise<ApiResponseData<T | null>> {
    const token = await getSessionToken()

    if (!token) return Promise.resolve({ data: null })

    return this.post(endpoint, body, token)
  }

  async deleteWithToken<T extends MessageResponse, B = undefined>(
    endpoint: string,
    body?: B
  ): Promise<ApiResponseData<T | null>> {
    const token = await getSessionToken()

    if (!token) return Promise.resolve({ data: null })

    return this.delete(endpoint, body, token)
  }

  async delete<T extends MessageResponse, B>(
    endpoint: string,
    body?: B,
    token?: string | null
  ): Promise<ApiResponseData<T | null>> {
    try {
      const headers = this.buildHeaders(token)

      const response = await fetch(`${this.baseUrl}${endpoint}`, {
        method: 'DELETE',
        headers,
        body: this.getBody<B>(body),
        // ban server redirects
        redirect: 'manual'
      })

      const status = response.status
      const data: T = await response.json()

      if (status === 401) {
        removeSessionToken()

        return { data: null, message: data?.message, status }
      } else if (status !== 200) {
        return { data: null, message: data?.message, status }
      } else {
        return { data, message: data?.message, status }
      }
    } catch (error) {
      Sentry.captureException(error)
      Bugsnag.notify(error as Error)

      return { data: null, error: String(error) }
    }
  }
}

export const apiService = new ApiService(
  process.env.NEXT_PUBLIC_API_URL || 'https://back.priceninja.ru/api'
)

export async function checkPhoneNumber(
  phoneNumber: string,
  type: 'phone' | 'sms' = 'phone'
): Promise<ApiResponseData<CheckPhoneNumberResponse | null>> {
  return apiService.post<CheckPhoneNumberResponse, CheckPhoneNumberBody>(
    '/auth/check-phone-number',
    {
      phone_number: phoneNumber,
      send_sms: type === 'sms'
    }
  )
}

export async function checkPhoneNumberCode(
  phoneNumber: string,
  code?: string
): Promise<ApiResponseData<CheckPhoneNumberCodeResponse | null>> {
  return apiService.post<CheckPhoneNumberCodeResponse, CheckPhoneNumberCodeBody>(
    '/auth/check-phone-number-code',
    {
      phone_number: phoneNumber,
      code
    }
  )
}

export async function login(
  phoneNumber: string,
  code: string
): Promise<ApiResponseData<LoginResponse | null>> {
  return apiService.post<LoginResponse, LoginBody>('/auth/login', {
    phone_number: phoneNumber,
    code
  })
}

type RegistrationPayload = {
  phone: string
  email: string
  name: string
}

export async function registration({
  phone,
  email,
  name
}: RegistrationPayload): Promise<ApiResponseData<RegistrationResponse | null>> {
  return apiService.post<RegistrationResponse, RegistrationBody>('/auth/registration', {
    phone_number: phone,
    email,
    name
  })
}

export async function checkEmailCode(
  email: string,
  code?: string
): Promise<ApiResponseData<CheckEmailCodeResponse | null>> {
  return apiService.post<CheckEmailCodeResponse, CheckEmailCodeBody>('/auth/check-email-code', {
    email,
    code
  })
}

export async function sendRegistrationEmail(
  email: string
): Promise<ApiResponseData<SendRegistrationEmailResponse | null>> {
  return apiService.post<SendRegistrationEmailResponse, SendRegistrationEmailBody>(
    '/auth/send-email',
    {
      email
    }
  )
}

export async function getUser(): Promise<ApiResponseData<UserAuth | null>> {
  return apiService.getWithToken<UserAuth>('/auth/me')
}

export async function getProfitData(body: ProfitBody): Promise<ApiResponseData<ProfitData | null>> {
  return apiService.getWithToken<ProfitData, ProfitBody>('/profit/settings', body)
}

export async function getProductsFiltersData(
  body: BodyWithFilters
): Promise<ApiResponseData<ProductsFiltersData | null>> {
  return apiService.getWithToken<ProductsFiltersData, BodyWithFilters>('/product/filters', body)
}

export async function getFiltersStatus(
  body: BodyWithFilters
): Promise<ApiResponseData<FilterStatuses | null>> {
  return apiService.postWithToken<FilterStatusResponse, BodyWithFilters>('/filters/status', body)
}

export async function changeName(
  name: string
): Promise<ApiResponseData<ChangeNameResponse | null>> {
  return apiService.postWithToken<ChangeNameResponse>(`/auth/profile/update?name=${name}`)
}

export async function changeEmailSendEmailCode(): Promise<
  ApiResponseData<ChangeEmailSendEmailCode | null>
> {
  return apiService.postWithToken<ChangeEmailSendEmailCode>(`/auth/profile/email/update?step=1`)
}

export async function changeEmailCheckEmailCode(
  code: string
): Promise<ApiResponseData<ChangeEmailCheckEmailCode | null>> {
  return apiService.postWithToken<ChangeEmailCheckEmailCode>(
    `/auth/profile/email/update?step=2&code=${code}`
  )
}

export async function changeEmailSentNewEmailCode(
  email: string
): Promise<ApiResponseData<ChangeEmailSentNewEmailCode | null>> {
  return apiService.postWithToken<ChangeEmailSentNewEmailCode>(
    `/auth/profile/email/update?step=3&email=${email}`
  )
}

export async function changeEmailConfirmation(
  email: string,
  code: string
): Promise<ApiResponseData<ChangeEmailSentNewEmailCode | null>> {
  return apiService.postWithToken<ChangeEmailSentNewEmailCode>(
    `/auth/profile/email/update?step=4&email=${email}&code=${code}`
  )
}

export async function changePhoneCallOldPhone(): Promise<
  ApiResponseData<ChangePhoneCallOldPhone | null>
> {
  return apiService.postWithToken<ChangePhoneCallOldPhone>(`/auth/profile/phone/update?step=1`)
}

export async function changePhoneCheckOldPhoneCode(
  code: string
): Promise<ApiResponseData<ChangePhoneCallOldPhone | null>> {
  return apiService.postWithToken<ChangePhoneCallOldPhone>(
    `/auth/profile/phone/update?step=2&code=${code}`
  )
}

export async function changePhoneCallNewPhone(
  phone: string
): Promise<ApiResponseData<ChangePhoneCallNewPhone | null>> {
  return apiService.postWithToken<ChangePhoneCallNewPhone>(
    `/auth/profile/phone/update?step=3&phone=${phone}`
  )
}

export async function changePhoneConfirmation(
  phone: string,
  code: string
): Promise<ApiResponseData<ChangeEmailSentNewEmailCode | null>> {
  return apiService.postWithToken<ChangeEmailSentNewEmailCode>(
    `/auth/profile/phone/update?step=4&phone=${phone}&code=${code}`
  )
}

export async function getTariffs(): Promise<ApiResponseData<Tariffs | null>> {
  return apiService.getWithToken<Tariffs>(`/tariffs`)
}

export async function getUpgradeTariffs(): Promise<ApiResponseData<Tariffs | null>> {
  return apiService.getWithToken<Tariffs>(`/auth/profile/tariff/to-change/list`)
}

type PromCodePayload = {
  promocode: string
  count_months: number
}

export async function checkPromo(
  frequencyId: number,
  data: PromCodePayload
): Promise<ApiResponseData<CheckPromo | null>> {
  return apiService.postWithToken<CheckPromo, PromCodePayload>(
    `/tariffs/${frequencyId}/check-promo`,
    data
  )
}

export async function checkUpgradePromo(
  frequencyId: string,
  data: PromCodePayload
): Promise<ApiResponseData<CheckPromo | null>> {
  return apiService.postWithToken<CheckPromo, PromCodePayload>(
    `/auth/profile/tariff/to-change/check-promo/${frequencyId}`,
    data
  )
}

export async function sendInvoiceRequest(
  description: string
): Promise<ApiResponseData<SendInvoiceRequest | null>> {
  // todo: уточнить у бека почему в урле передаются параметры в пост запросе
  return apiService.postWithToken<SendInvoiceRequest>(
    `/orders/invoice-request?description=${description}`
  )
}

export async function getPaymentHistory(): Promise<ApiResponseData<PaymentHistory | null>> {
  return apiService.getWithToken<PaymentHistory>('/auth/profile/invoices')
}

export async function getPanelCharts(
  body: BodyWithFilters
): Promise<ApiResponseData<PanelChartsData | null>> {
  return apiService.postWithToken<PanelChartsData, PanelChartBody>('/panel-charts', body)
}

export async function sendMassUpload(
  file: File
): Promise<ApiResponseData<SendMassUploadResponse | null>> {
  const formData = new FormData()
  formData.append('file', file)
  return apiService.postWithToken('/connection/import-file', formData)
}

export async function getApiKeys(): Promise<ApiResponseData<ApiKeysData | null>> {
  return apiService.getWithToken<ApiKeysData>('/api-keys')
}

export async function addApiKey(
  body: ApiKeyBody
): Promise<ApiResponseData<AddApiKeyResponse | null>> {
  return apiService.postWithToken<AddApiKeyResponse, ApiKeyBody>('/api-key', body)
}

export async function deleteApiKey(
  id: string
): Promise<ApiResponseData<DeleteApiKeyResponse | null>> {
  return apiService.deleteWithToken<DeleteApiKeyResponse>(`/api-key/${id}`)
}
