import { MediaProps } from '@/types/media'

const NinjaProfileLogo = ({ width = '21', height = '20' }: MediaProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 21 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.7891 10C20.7891 15.5228 16.3119 20 10.7891 20C5.26621 20 0.789062 15.5228 0.789062 10C0.789062 4.47715 5.26621 0 10.7891 0C16.3119 0 20.7891 4.47715 20.7891 10ZM15.8819 13.6851C18.8073 13.6851 18.9418 10.3114 18.9418 8.85954C18.9418 8.0196 17.626 8.26036 15.7421 8.60509L15.7421 8.60509C14.3695 8.85624 12.6953 9.16259 11.0085 9.14641C10.0548 9.13727 9.0971 9.00648 8.18763 8.83275V9.73454C8.18763 10.51 7.55903 11.1386 6.78361 11.1386C6.00819 11.1386 5.37959 10.51 5.37959 9.73454V8.19626C3.74266 7.81565 2.63584 7.66031 2.63584 8.59889C2.63584 10.4325 3.11915 13.6851 5.59494 13.6851C6.94135 13.6851 7.90918 13.1281 8.79218 12.62C9.53286 12.1937 10.2138 11.8018 11.0085 11.8018C11.7673 11.8018 12.2346 12.1591 12.7622 12.5624C13.4459 13.0851 14.2308 13.6851 15.8819 13.6851ZM15.9275 8.60314C15.1628 8.74307 14.3044 8.90014 13.4024 9.00901V9.74046C13.4024 10.5126 14.0283 11.1386 14.8005 11.1386C15.5772 11.1386 16.2051 10.5054 16.1985 9.72867L16.1886 8.55545L15.9276 8.60313L15.9275 8.60314Z"
        fill="currentColor"
      />
    </svg>
  )
}

export { NinjaProfileLogo }
