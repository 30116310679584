'use client'

import { useTestServerAuth } from '@/hooks/useTestServerAuth'

type Props = {
  children: React.ReactNode
}

export const TestCheck = ({ children }: Props) => {
  const testServerAuth = useTestServerAuth()

  if (!testServerAuth) return null

  return children
}
