import { NinjaIcon } from '@/media'

function Logo() {
  return (
    <div className="font-logo flex px-8 items-center space-x-3">
      <NinjaIcon />
      <span className="block text-[19px] text-black font-bold leading-[25px] relative top-[1px]">
        ПРАЙС НИНДЗЯ
      </span>
    </div>
  )
}

export default Logo
