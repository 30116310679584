'use client'

import { useState } from 'react'

import { useAuthSteps } from '@/contexts/auth.steps.context'

import { Body } from '../Body'
import { Footer } from '../Footer'
import { Header } from '../Header'
import { PublicOffer } from '../PublicOffer'
import { NameCheckForm } from '../forms/NameCheckForm'
import AuthStepLayout from './AuthStepLayout'

export function NameStep() {
  const authStepsContext = useAuthSteps()
  const name = authStepsContext?.name || ''
  const [isError, setIsError] = useState(false)

  function handleNext() {
    if (name.length && name.trim().length > 0) {
      authStepsContext?.setNextStep()
    } else {
      setIsError(true)
    }
  }

  function handleChangeName(e: React.ChangeEvent<HTMLInputElement>) {
    authStepsContext?.setName(e.target.value)
    setIsError(false)
  }

  return (
    <AuthStepLayout>
      <Header title="Представьтесь" />
      <Body>
        <NameCheckForm
          value={name}
          onChange={handleChangeName}
          isErrorAuth={isError}
          onSubmit={handleNext}
        />
        <PublicOffer />
      </Body>
      <Footer
        onNextClick={handleNext}
        onPrevClick={authStepsContext?.setPrevStep}
        isLoading={false}
      />
    </AuthStepLayout>
  )
}
