'use client'

import React from 'react'

interface Props {
  children: React.ReactNode
}

export function Body({children}: Props) {
  return (
    <div className="p-5 lg:px-10 lg:py-[30px]">
      {children}
    </div>
  )
}
