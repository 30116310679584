'use client'

import Input from '../Input'

export interface CodeCheckFormProps {
  value: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  isErrorAuth?: boolean
  label: string
}

export function CodeCheckForm({ value, onChange, isErrorAuth, label }: CodeCheckFormProps) {
  return (
    <Input
      value={value}
      onChange={onChange}
      isErrorAuth={isErrorAuth}
      inputId="codeInput"
      label={label}
      placeholder="00 00"
      autoComplete="off"
      type="string"
      inputMode="numeric"
      className="w-[94px] lg:min-w-[114px] lg:w-[114px]"
    />
  )
}
