import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import BugsnagPerformance from '@bugsnag/browser-performance'

const COMMON_SETTINGS = {
  apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY ?? '',
  appVersion: process.env.NEXT_PUBLIC_COMMIT_HASH,
  releaseStage: process.env.NEXT_PUBLIC_STAGE ?? 'local',
  enabledReleaseStages: ['production', 'stage'],
  attributeCountLimit: 200,
  attributeStringValueLimit: 5000,
  attributeArrayLengthLimit: 5000
}

export function startBugsnag() {
  if (process.env.NODE_ENV === 'production') {
    if (typeof window === 'undefined') {
      Bugsnag.start({
        ...COMMON_SETTINGS
      })
    } else {
      Bugsnag.start({
        ...COMMON_SETTINGS,
        plugins: [new BugsnagPluginReact()]
      })

      BugsnagPerformance.start({
        ...COMMON_SETTINGS
      })
    }
  }
}
