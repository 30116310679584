import { Logo } from '@/components/Logo'
import { StarIcon } from '@/media'

export function AuthenticationLayout({
  children // will be a page or nested layout
}: {
  children: React.ReactNode
}) {
  return (
    <div className="w-full h-screen bg-gray-100 flex items-center justify-center relative">
      <div className="absolute top-5 mx-auto">
        <Logo />
      </div>
      <div className="absolute bottom-5 mx-auto">
        <StarIcon />
      </div>
      <div className="font-logo fixed bottom-0 lg:relative w-full lg:w-[450px]">{children}</div>
    </div>
  )
}
